<template>
  <div class="container">
    <div class="section">
        <div class="section-heading">
            <h3 class="title is-2">Contact</h3>
            <h4 class="subtitle is-5">Contact us from SNS</h4>
        </div>
        <div class="social-media">
            <a href="https://www.facebook.com/tanaka.masaya3" target="_blank" class="button is-light is-large"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
            <a href="https://twitter.com/masaya3" target="_blank" class="button is-light is-large"><i class="fa fa-twitter" aria-hidden="true"></i></a>
            <a href="https://www.youtube.com/channel/UCGxrktkQRioE441i2JK8jIg" target="_blank" class="button is-light is-large"><i class="fa fa-youtube" aria-hidden="true"></i></a>
        </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.social-media {
  margin-top: 30px;
}

.social-media a {
  margin-right: 10px;
}

.field:not(:last-child) {
  margin-bottom: 20px;
}
</style>